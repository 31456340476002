<template>
  <div class="product-information">
    <div class="container01 lazyload">
      <h2><span>JJ ENGLISHエクササイズは、価格で選べる２タイプ。いずれも、</span>学習者の意欲に「確実性」でこたえます。</h2>
    </div>
    <figure class="f1"><img v-lazy="'/top2020/img/tc13_01.webp'" alt=""></figure>
    <p class="app">アプリみたいに学習できる！</p>
    <figure class="f2"><img v-lazy="'/top2020/img/logo04.webp'" alt=""></figure>
    <div class="price flexb">
      <p class="flex">送料<br>無料</p>
      <div class="txt">
        <p class="p1">32,450円</p>
        <p class="p2">（本体価格：29,500円）</p>
        <p class="bar1"></p>
        <p class="bar2"></p>
      </div>
    </div>
    <div class="price3">
      <p class="covid">
        <img v-lazy="'/top2020/img/5th.png'" alt=""><br>
        <span class="note">ぜひお見逃しなく！</span>
      </p>
      <div class="txt">
        <p class="p1"><i class="arrow fas fa-arrow-down"></i>16,500円</p>
        <p class="p2">（本体価格：15,000円）</p>
      </div>
    </div>
    <figure class="f3"><img v-lazy="'/top2020/img/tc7_07.webp'" alt=""></figure>
    <div class="container02 lazyload inner01">
      <h4>【教材内容】</h4>
      <p>１.学習用テキスト４冊（162P×３冊、122P×１冊）<br>２.ウエルカムブック１冊（40P）<br>３.動画MP4ファイル全311本（約25.6時間）<br>４.音声MP3ファイル全281本（約8.6時間）<br>(※３、４→インストール用ディスク４枚に収録)</p>
      <p class="normal">●ディスクはパソコンでの再生専用です。収録されている音声ファイル（MP3）、動画ファイル（MP4）をパソコンに内蔵されているプレーヤーで再生してください。<span class="red">ご家庭のDVDプレーヤーでは再生できません。</span><br>●音声ファイル、動画ファイルはパソコンに一度コピーしてからスマホやタブレットに移すことができます。<br>●すべての音声ファイル、動画ファイルは、購入者専用サイトにもアップロードされていますので、スマホやパソコンに直接ダウンロードやストリーミング再生も可能です（Wi-Fi接続時でのご利用をおすすめします）。<br><br></p>
      <h4>【お支払い方法について】</h4>
      <p class="normal">
        クレジットカード（分割可）／Amazon Pay／NP後払い（銀行、郵便局、コンビニ／LINE Pay）／ヤマト代引き（カード可）が利用できます。
        <a href="#shopping-guide">詳しくはコチラ</a>
      </p>
      <div class="qq-banner">
        <p>
          <img v-lazy="'/top2020/img/qq_banner.webp'">
          <a href="#tc14">詳しくはコチラ</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductInformation',
}
</script>

<style scoped lang="scss">
.product-information {
  .covid {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 40px;
    }
    .welcome {
      font-size: 40px;
      font-weight: bold;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 40px;
    }
  }
  .covid img {
    width: 60%;
  }
  .covid p {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    .note {
      font-size: 14px;
    }
  }
  .app {
    color: #fe3340;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }
  .container01 {
    height: 250px;
    padding-left: 10px;
    padding-right: 10px;
    &.lazyloaded {
      background-image: url("/top2020/img/tc13_bg1.webp");
    }
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .container01 figure {
    width: 220px;
    margin: 0 auto 20px auto;
  }
  .container01 h2 span {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-bottom: 0px;
    display: block;
  }
  .container01 h2 {
    font-size: 50px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .f1 {
    width: 520px;
    margin: -40px auto 30px auto;
  }
  .f2 {
    width: 400px;
    margin: 0px auto 20px auto;
  }
  .f3 {
    width: 480px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
  .f4 {
    width: 480px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  .price {
    width: 300px;
    margin: 40px auto 30px auto;
    align-items: center;
  }
  .price .flex {
    width: 90px;
    height: 90px;
    background-color: #fe3340;
    color: #fff;
    font-size: 22px;
    line-height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .price .txt {
    position: relative;
  }
  .price .txt p.p1 {
    font-size: 45px;
    line-height: 32px;
    color: #fe3340;
    margin-bottom: 10px;
  }
  .price .txt p.p2 {
    font-size: 18px;
    line-height: 32px;
    color: #fe3340;
  }
  .price .txt p.bar1,
  .price .txt p.bar2 {
    position: absolute;
    top: 16px;
    left: 0px;
    right: 0px;
    height: 4px;
    background-color: #fe3340;
  }
  .price .txt p.bar1 {
    transform: rotate(20deg);
  }
  .price .txt p.bar2 {
    transform: rotate(-20deg);
  }
  .price2 {
    width: 650px;
    margin: 0px auto 30px auto;
    align-items: center;
  }
  .price2 .flex {
    width: 90px;
    height: 90px;
    background-color: #fe3340;
    color: #fff;
    font-size: 22px;
    line-height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .price2 .txt p.p1 {
    font-size: 45px;
    line-height: 32px;
    color: #fe3340;
    margin-bottom: 10px;
  }
  .price2 .txt p.p2 {
    font-size: 18px;
    line-height: 32px;
    color: #fe3340;
  }
  .price3 {
    display: flex;
    justify-content: center;
    width: 500px;
    margin: 0px auto 30px auto;
    align-items: center;
    position: relative;
  }
  .price3 .covid {
    text-align: center;
    font-size: 18px;
    width: 200px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .price3 .covid .note {
    font-size: 14px;
  }
  .price3 .txt {
    margin-left: 10px;
    margin-right: 10px;
  }
  .price3 .txt p.p1 {
    font-size: 60px;
    line-height: 32px;
    color: #fe3340;
    margin-bottom: 20px;
    position: relative;
  }
  .price3 .txt p.p2 {
    font-size: 18px;
    line-height: 32px;
    color: #fe3340;
  }
  .price3 .txt .arrow {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    font-size: 31px;
    text-align: center;
    height: 40px;
  }
  .container02 {
    padding: 60px;
    &.lazyloaded {
      background-image: url("/top2020/img/tc13_bg2.webp");
    }
    background-repeat: repeat;
    margin-bottom: 60px;
  }
  .container02 p {
    font-size: 20px;
    line-height: 1.6em;
    margin-bottom: 20px;
    .red {
      color: #fe3340;
    }
    &.normal {
      font-size: 17px;
    }
  }
  .container02 p:last-child {
    margin-bottom: 0;
  }
  .container02 h4 {
    font-size: 20px;
    line-height: 1.6em;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .container02 {
    .qq-banner {
      margin-top: 60px;
      text-align: center;
      p {
        display: inline-block;
        position: relative;
        img {
          width: 400px;
        }
        a {
          position: absolute;
          bottom: -6px;
          right: -70px;
          @media only screen and (max-width: 768px) {
            position: relative;
          }
        }
      }
    }
    .new-year {
      margin-top: 60px;
      h4, p {
        text-align: center;
        &.strong {
          font-size: 1.6em;
        }
      }
      p {
        margin-bottom: 10px;
      }
      .red {
        color: #fe3340;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .container01 {
      height: 220px;
    }
    .container01 figure {
      width: 220px;
      margin: 0 auto 20px auto;
    }
    .container01 h2 span {
      font-size: 20px;
    }
    .container01 h2 {
      font-size: 30px;
    }
    .f1 {
      width: 80%;
    }
    .f2 {
      width: 80%;
      margin: 0px auto 10px auto;
    }
    .f3 {
      width: calc(100% - 40px);
    }
    .f4 {
      width: calc(100% - 40px);
    }
    .price {
      width: 240px;
      margin: 0px auto 30px auto;
      align-items: center;
    }
    .price .flex {
      width: 60px;
      height: 60px;
      font-size: 16px;
      line-height: 20px;
      margin-right: 5px;
    }
    .price .txt {
      margin-bottom: 10px;
    }
    .price .txt p.p1 {
      font-size: 31px;
      line-height: 32px;
      margin-bottom: 0;
    }
    .price .txt p.p2 {
      font-size: 15px;
      line-height: 20px;
    }
    .price2 {
      width: 240px;
      margin: 0px auto 30px auto;
      align-items: center;
    }
    .price2 .flex {
      width: 60px;
      height: 60px;
      font-size: 16px;
      line-height: 20px;
      margin-right: 5px;
    }
    .price2 .txt {
      margin-bottom: 10px;
    }
    .price2 .txt p.p1 {
      font-size: 31px;
      line-height: 32px;
      margin-bottom: 0;
    }
    .price2 .txt p.p2 {
      font-size: 15px;
      line-height: 20px;
    }
    .price3 {
      width: 334px;
      left: 0;
      margin-top: 30px;
    }
    .price3 .covid {
      font-size: 15px;
      width: 140px;
    }
    .price3 .txt {
      margin-bottom: 10px;
    }
    .price3 .txt .arrow {
      font-size: 26px;
      top: -32px;
    }
    .price3 .txt p.p1 {
      font-size: 38px;
      line-height: 32px;
      margin-bottom: 10px;
    }
    .price3 .txt p.p2 {
      font-size: 15px;
      line-height: 20px;
    }
    .container02 {
      padding: 30px 20px;
      margin-bottom: 30px;
      width: 90%;
      &.lazyloaded {
        background-image: url("/top2020/img/sp_tc13_bg2.webp");
      }
      p {
        font-size: 17px;
      }
    }
  }
}
</style>
