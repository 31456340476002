var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-information"},[_vm._m(0),_c('figure',{staticClass:"f1"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/tc13_01.webp'),expression:"'/top2020/img/tc13_01.webp'"}],attrs:{"alt":""}})]),_c('p',{staticClass:"app"},[_vm._v("アプリみたいに学習できる！")]),_c('figure',{staticClass:"f2"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/logo04.webp'),expression:"'/top2020/img/logo04.webp'"}],attrs:{"alt":""}})]),_vm._m(1),_c('div',{staticClass:"price3"},[_c('p',{staticClass:"covid"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/5th.png'),expression:"'/top2020/img/5th.png'"}],attrs:{"alt":""}}),_c('br'),_c('span',{staticClass:"note"},[_vm._v("ぜひお見逃しなく！")])]),_vm._m(2)]),_c('figure',{staticClass:"f3"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/tc7_07.webp'),expression:"'/top2020/img/tc7_07.webp'"}],attrs:{"alt":""}})]),_c('div',{staticClass:"container02 lazyload inner01"},[_c('h4',[_vm._v("【教材内容】")]),_vm._m(3),_vm._m(4),_c('h4',[_vm._v("【お支払い方法について】")]),_vm._m(5),_c('div',{staticClass:"qq-banner"},[_c('p',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/top2020/img/qq_banner.webp'),expression:"'/top2020/img/qq_banner.webp'"}]}),_c('a',{attrs:{"href":"#tc14"}},[_vm._v("詳しくはコチラ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container01 lazyload"},[_c('h2',[_c('span',[_vm._v("JJ ENGLISHエクササイズは、価格で選べる２タイプ。いずれも、")]),_vm._v("学習者の意欲に「確実性」でこたえます。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price flexb"},[_c('p',{staticClass:"flex"},[_vm._v("送料"),_c('br'),_vm._v("無料")]),_c('div',{staticClass:"txt"},[_c('p',{staticClass:"p1"},[_vm._v("32,450円")]),_c('p',{staticClass:"p2"},[_vm._v("（本体価格：29,500円）")]),_c('p',{staticClass:"bar1"}),_c('p',{staticClass:"bar2"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('p',{staticClass:"p1"},[_c('i',{staticClass:"arrow fas fa-arrow-down"}),_vm._v("16,500円")]),_c('p',{staticClass:"p2"},[_vm._v("（本体価格：15,000円）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("１.学習用テキスト４冊（162P×３冊、122P×１冊）"),_c('br'),_vm._v("２.ウエルカムブック１冊（40P）"),_c('br'),_vm._v("３.動画MP4ファイル全311本（約25.6時間）"),_c('br'),_vm._v("４.音声MP3ファイル全281本（約8.6時間）"),_c('br'),_vm._v("(※３、４→インストール用ディスク４枚に収録)")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"normal"},[_vm._v("●ディスクはパソコンでの再生専用です。収録されている音声ファイル（MP3）、動画ファイル（MP4）をパソコンに内蔵されているプレーヤーで再生してください。"),_c('span',{staticClass:"red"},[_vm._v("ご家庭のDVDプレーヤーでは再生できません。")]),_c('br'),_vm._v("●音声ファイル、動画ファイルはパソコンに一度コピーしてからスマホやタブレットに移すことができます。"),_c('br'),_vm._v("●すべての音声ファイル、動画ファイルは、購入者専用サイトにもアップロードされていますので、スマホやパソコンに直接ダウンロードやストリーミング再生も可能です（Wi-Fi接続時でのご利用をおすすめします）。"),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"normal"},[_vm._v(" クレジットカード（分割可）／Amazon Pay／NP後払い（銀行、郵便局、コンビニ／LINE Pay）／ヤマト代引き（カード可）が利用できます。 "),_c('a',{attrs:{"href":"#shopping-guide"}},[_vm._v("詳しくはコチラ")])])
}]

export { render, staticRenderFns }